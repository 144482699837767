// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-csapat-js": () => import("./../../../src/pages/csapat.js" /* webpackChunkName: "component---src-pages-csapat-js" */),
  "component---src-pages-floorball-js": () => import("./../../../src/pages/floorball.js" /* webpackChunkName: "component---src-pages-floorball-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-tortenet-js": () => import("./../../../src/pages/tortenet.js" /* webpackChunkName: "component---src-pages-tortenet-js" */),
  "component---src-pages-utanpotlas-js": () => import("./../../../src/pages/utanpotlas.js" /* webpackChunkName: "component---src-pages-utanpotlas-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */)
}

